@font-face {
  font-family: "Pridi";
  src: local("Pridi"),
     url(/static/media/Pridi-ExtraLight.30a2a54d.ttf)
      format("truetype"),
    url(/static/media/Pridi-Light.a52f6802.ttf) format("truetype"),
    url(/static/media/Pridi-Medium.9691cdc5.ttf) format("truetype"),
    url(/static/media/Pridi-Regular.86b82fd4.ttf) format("truetype"),
    url(/static/media/Pridi-SemiBold.cc3af4d7.ttf) format("truetype");
}
.agent-function {
  margin-left: -10px;
  margin-bottom: -10px;
  background-color: black !important;
}
.nav-hide {
  margin-top: -55px;
}
.btn-skip {
  width: 100%;
  color: #808080c4;
  cursor: pointer !important;
}
.btn-skip:hover {
  text-decoration: underline;
}
.div-skip {
  text-align: center;
}
.btn-confirm {
  width: 100%;
}
.icon-rating {
  display: block;
}
.text-rating {
  display: block;
}
.span-rate {
  display: inline-block;
  cursor: pointer;
}
.input-rating {
  border-radius: 15px;
  height: 15vh;
  outline: none;
  width: calc(100% - 10px);
  resize: none;
  margin-bottom: -4px;
  background-color: white;
  border: none;
  padding: 5px;
}
.field-rating {
  background-color: #ececec;
  padding: 10px;
  border-radius: 20px;
}
.point-img {
  margin-left: 10px;
  margin-right: 10px;
  width: 60px;
}
.div-icon-rating {
  text-align: center;
}
.header-rating2 {
  margin-bottom: 10px;
}
.nav-rating {
  font-size: 22px;
  color: black;
  text-align: center;
  background-color: white !important;
  border-bottom: 1px solid#EFEFEF;
}
.nav {
  line-height: 58px !important;
}
.label-rating {
  margin-bottom: 3px;
}
@media only screen and (max-width: 600px) {
  button {
    font-size: 15px;
  }
  div {
    font-size: 15px;
  }
  .point-left {
    margin-right: 10px;
    width: 50px;
  }
  .point-right {
    width: 50px;
  }
}
.noselect {
  opacity: 0.3;
}

.rate-iframe {
  width: 100%;
  height: 500px;
  border-width: 1px;
  border-color: white;
  border-style: dotted;
}

.typerealemergency {
  margin-left: 3px;
  margin-right: 5px;
  height: 24px;
  margin-top: -24px;
  margin-bottom: -5px;
}
.emergency-choice {
  margin-left: 30%;
}
.emregency-text {
  font-size: 20px !important;
}
.normal-text {
  font-size: 20px !important;
}
@media (min-width: 680px) {
  /* body{
        background-color: black;
    } */
}
@media (max-width: 751px) {
  .close-thread-chatboxcontent {
    top: 144px;
    background-color: transparent !important;
  }
  body {
    background-color: #80808024 !important;
  }
}
.title-member-header {
  font-size: 20px;
  font-family: "Pridi" !important;
  font-weight: bold !important;
  color: #168ace;
}
.title-member-header-emergency {
  font-size: 20px;
  font-family: "Pridi" !important;
  font-weight: bold !important;
  color: red;
}
.title-member-image {
  margin-top: 20px;
  width: 150px;
  margin-bottom: 10px;
}
.normal-text {
  font-family: "Pridi";
  font-weight: bold;
  font-size: 18px !important;
}
.normal-text {
  margin-top: 10px !important;
}
.emregency-text {
  font-family: "Pridi";
  font-weight: bold;
  font-size: 18px !important;
}
.emregency-text {
  margin-top: 10px !important;
}
.emergency-choice {
  /* width: 0%; */
  /* display: inline-block; */
  padding-left: 30px;
  width: 50%;
  border: none;
}
.emergency-select {
  margin-left: 25%;
}
.emergency-radio {
  margin-top: 10px;
  font-family: "Pridi";
  font-weight: bold;
  font-size: 18px !important;
}
.receiving-cancel,
.receiving-cancel:hover,
.receiving-cancel:focus {
  background-color: gray !important;
}
.full-width {
  width: 100%;
}
.ReactModal__Overlay {
  z-index: 9999;
}
.center {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}
.welcome {
  font-size: 20px;
}

